<template>
  <div class="debt-details">
    <v-card class="mb-2">
      <v-card-title> Laporan Utang </v-card-title>

      <v-card-text v-text="supplier.data.supp_name"></v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="filter.dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Sisa Utang </v-card-title>

      <v-card-text>
        <h2 v-text="summaries.remaining"></h2>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr class="header">
                <td>Tagihan Awal</td>
                <td v-text="summaries.total" class="text-right"></td>
              </tr>
              <tr>
                <td>Potongan Retur</td>
                <td v-text="summaries.returns" class="text-right"></td>
              </tr>
              <tr class="header">
                <td>Total Tagihan</td>
                <td v-text="summaries.afterReturns" class="text-right"></td>
              </tr>
              <tr>
                <td>Sudah Lunas</td>
                <td v-text="summaries.paymentDone" class="text-right"></td>
              </tr>
              <tr>
                <td>Dibayar Sebagian</td>
                <td v-text="summaries.paymentNotDone" class="text-right"></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="getData"
      ></v-text-field>

      <v-data-table
        :headers="debts.headers"
        :items="debts.data"
        :loading="debts.loading"
        :options.sync="options"
        :server-items-length="debts.count"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'

export default {
  components: { DateRangePicker },

  name: 'DebtDetails',

  data() {
    return {
      filter: {
        dateRange: null,
      },

      debts: {
        allocated: 0,

        count: 0,

        data: [],

        headers: [
          { text: 'No Transaksi', value: 'trans_no' },
          { text: 'Referensi', value: 'reference' },
          { text: 'Tanggal', value: 'tran_date' },
          { text: 'Total', value: 'total' },
          { text: 'Potongan Retur', value: 'return_alloc' },
          { text: 'Sudah Lunas', value: 'payment_done_alloc' },
          { text: 'Dibayar Sebagian', value: 'payment_not_done_alloc' },
          { text: 'Sisa Utang', value: 'amount' },
        ],

        loading: false,

        paymentDoneAllocated: 0,

        paymentNotDoneAllocated: 0,

        remaining: 0,

        returnAllocated: 0,

        total: 0,

        totalAfterReturns: 0,
      },

      options: {},

      search: '',

      supplier: {
        data: [],

        loading: false,
      },

      supplierID: null,

      summaries: {
        loading: false,
        total: 0,
        returns: 0,
        afterReturns: 0,
        paymentDone: 0,
        paymentNotDone: 0,
        remaining: 0,
      },
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.getData()
      },
    },

    'filter.dateRange'() {
      this.getData()
    },
  },

  mounted() {
    this.supplierID = this.$route.params.supplierID

    this.getSupplier()

    this.getSummary()
  },

  methods: {
    getSummary() {
      this.$http
        .get('/suppliers/debts/summary', {
          params: {
            startDate: this.date2sql(this.filter.dateRange[0]),
            endDate: this.date2sql(this.filter.dateRange[1]),
            supplier_id: this.supplierID,
          },
        })
        .then(({ data }) => {
          this.summaries.total = data.total
          this.summaries.returns = data.returns
          this.summaries.afterReturns = data.after_returns
          this.summaries.paymentDone = data.payments_done
          this.summaries.paymentNotDone = data.payments_not_done
          this.summaries.remaining = data.remaining
        })
    },

    getData() {
      if (!this.filter.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.debts.data = []
      this.debts.loading = true

      this.$http
        .get('/purchasing/transactions/report/20/' + this.supplierID, {
          params: {
            debt: true,
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
          },
        })
        .then(({ data }) => {
          this.debts.allocated = data.allocated

          this.debts.count = data.count

          data.result.forEach((debt) => {
            debt.tran_date = this.sql2date(debt.tran_date)
          })

          this.debts.data = data.result

          this.debts.paymentDoneAllocated = data.payment_done_allocated

          this.debts.paymentNotDoneAllocated = data.payment_not_done_allocated

          this.debts.returnAllocated = data.return_allocated

          this.debts.remaining = data.total

          this.debts.total = data.realTotal

          this.debts.totalAfterReturns = data.total_after_returns

          this.debts.loading = false
        })
    },

    getSupplier() {
      this.supplier.loading = false

      this.$http
        .get('/purchasing/suppliers?supplier_id=' + this.supplierID)
        .then((result) => {
          this.supplier.data = result.data
          this.supplier.loading = false
        })
    },

    refresh() {
      this.getSummary()
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.header td {
  background-color: #333333;
}
</style>
